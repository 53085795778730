.user-menu {
    position: absolute;
    margin-right: 20px;
    top: 100px;
    right: 0px;
    display: none;
    flex-wrap: wrap;
    border-radius: $border-radius-sm;
    background-color: $white;
    opacity: 0;
    visibility: none;
    max-width: 480px;

    @include media-breakpoint-down(sm) {
        top: 100px;
        align-items: flex-start;
        overflow: hidden;
        opacity: 0;
        max-width: 100%;
    }

    strong {
        font-size: $font-size-sm;
    }

    .user-menu__new {
        border-top: 1px solid $gray-400;
        background-color: $gray-700;
    }

    label {
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        color: $gray-700;
    }

    .secondary {
        color: $gray-700;
        font-size: $font-size-xs;
        text-transform: uppercase;
        a {
            color: inherit;
        }
    }
    &--active{
        opacity: 1;
        visibility: visible;
        display: flex;
    }
    &:before{
        content: '';
        position: absolute;
        right: 10px;
        top: -5px;
        display: block;
        background-color: $white;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        border-radius: $border-radius-sm;
    }
}
.main-header.main-sticky~.user-menu {
    top: 70px;
}

@include media-breakpoint-down(md) {
    .user-menu {
        top: 100px;
        margin-right: 0;
        border: none;
        width: 100%;
        min-height: 33vh;
        max-width: 100%;
        border-radius: 0px;
        padding-top: $padding-lg;
        &:before{
            content: none;
        }
        h2 {
            font-size: 18px;
            padding-top: $padding-xxs;
            padding-bottom: $padding-xxs;
            border: none;
        }
    }
}