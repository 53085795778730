.vitec-brands {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0px;
    padding: 0px 0px 0px 15px;
  
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  
  .vitec-brands__item {
    margin-right: 18px;
  
    ul.head-brands {
      list-style: none;
      padding: 0;
      margin: 0;
  
      li {
        float: left;
  
        a {
          display: block;
          width: 65.29px;
          height: 40px;
          margin-top: 0px;
          padding-right: 0;
          margin-right: $padding-xs;
          overflow: hidden;
          text-indent: -999px;
          vertical-align: middle;
          opacity: 0.35;
          transition: opacity ease-in-out 200ms;
  
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
  
  .sponsors-menu__item {
    font-size: 13px;
    text-transform: uppercase;
  
    a {
      color: $primary;
  
      &:hover {
        text-decoration: none;
      }
    }
  
  
  }
  
  /* Group Brands  */
  
  .group-brands {
    background: $gray-300;
  
    @include media-breakpoint-down(md) {
      display: none;
    }
  
    &__title {
      width: 340px;
      min-width: 291px;
      padding: 32px 41px 0;
      border-left: 1px solid #eaeaea;
  
      p {
        font-size: $font-size-xsm;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $gray-600;
  
        @include media-breakpoint-down(lg) {
          font-size: $font-size-sm;
        }
      }
    }
  
    &__items {
      padding-left: 20px;
      width: 100%;
      max-width: 1400px;
  
      @include media-breakpoint-down(xlg) {
        max-width: 900px;
      }
  
      ul {
        width: 100%;
        margin: 0px;
        padding: $padding-xs;
        list-style-type: none;
  
        li {
          float: left;
  
          span {
            display: none;
          }
  
          a {
            display: block;
            width: 90px;
            height: 56.25px;
            margin-top: 0px;
            padding-right: 0;
            margin-right: $padding-lg;
            overflow: hidden;
            text-indent: -9999px;
            vertical-align: middle;
            opacity: 0.50;
            transition: opacity ease-in-out 200ms;
  
            @include media-breakpoint-down(xl) {
              width: 75px;
              height: 46.875px;
              margin-right: $padding-sm;
            }
  
            @include media-breakpoint-down(xlg) {
              width: 65px;
              height: 40.675px;
              margin-right: $padding-xs;
            }
  
            &:hover {
              opacity: 0.75;
            }
  
            span {
              display: none;
            }
          }
  
          &.custom-brand {
            a {
              text-indent: initial;
  
              img {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
  
      }
    }
  
  }

  //todo: da sistemare 17/07/2018
@media only screen and (max-width: 991px) {
	#LH2013 {
		left: 10px;
		right: auto;
		width: 208px;
	}
}
@media only screen and (max-width: 770px) {
	#LH2013 {
		right: 0px!important;
		left: auto;
		width: 55px;
		bottom: 220px!important;
		overflow: hidden;
		border-radius: 4px!important;
		img{
			max-width: 999px!important;
		}
	}
}
.vitec-list::after,
.vitec-section .cols-container:after {
	display: block;
	clear: both;
	content: ""
}
.vitec-section * {
	font-family: Arial, Helvetica, sans-serif;
	padding: 0;
	margin: 0;
	font-size: inherit;
	text-transform: none
}

.vitec-section .container {
	max-width: 1180px;
	margin: 0 auto;
	padding: 0 30px
}

.vitec-section .yellow {
	color: #fecb00
}

.vitec-section .collapsible-section {
	background: #000
}


.vitec-section {
	background-color: #535353;
	float: left;
	height: auto;
	width: 100%;
	@include media-breakpoint-down(md) {
		position: relative;
		z-index: 999;
	}
}

.vitec-menu {
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3
}

.vitec-menu>li {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid
}

.vitec-section .close-vitec-menu {
	display: block;
	width: 22px;
	height: 22px;
	background: url(https://cdn.manfrotto.com/pub/static/frontend/VIS/manfrotto/it_IT/images/vitec-brands-bar/close.png) no-repeat;
	position: absolute;
	right: 0;
	top: 30px;
	cursor: pointer
}

.vitec-section h2 {
	font-size: 37px;
	font-weight: 700;
	color: #fff;
	line-height: 36px
}

.vitec-section h3 {
	font-size: 22px;
	font-weight: 700;
	line-height: 30px;
	color: #fff;
	padding: 5px 0
}

.vitec-menu,
.vitec-menu li,
.vitec-menu li a {
	display: block
}

.vitec-menu ul {
	margin-bottom: 25px
}

.vitec-section h2 span {
	display: block
}

.vitec-section .yellow-separator {
	padding-left: 90px;
	border-left: 2px solid #fecb00
}

.vitec {
	padding: 5px 0;
	display: block
}

.vitec .vitec-logo {
	float: left;
	height: 50px;
	width: 160px;
	font-size: 11px;
	font-weight: 400;
	color: #fff;
	padding-left: 40px;
	position: relative
}

.vitec .vitec-logo .logo-image {
	display: block;
	position: absolute;
	left: 0;
	height: 50px;
	width: 100%;
	background: url(https://cdn.manfrotto.com/pub/static/frontend/VIS/manfrotto/it_IT/images/vitec-brands-bar/vitec-endorsement.svg) center left no-repeat;
	image-rendering: crisp-edges;
	background-size: auto 37.5pt;
	background-repeat: no-repeat;
	background-color: #535353
}

.vitec .vitec-sites {
	float: right;
	cursor: pointer;
	line-height: 50px;
	font-size: 13px;
	font-weight: 700;
	color: #fff;
	padding-right: 15px;
	background: url(https://cdn.manfrotto.com/pub/static/frontend/VIS/manfrotto/it_IT/images/vitec-brands-bar/uparrow.png) center right no-repeat
}

.vitec .vitec-sites.active {
	background: url(https://cdn.manfrotto.com/pub/static/frontend/VIS/manfrotto/it_IT/images/vitec-brands-bar/downarrow.png) center right no-repeat
}

.vitec-list {
	padding: 65px 0;
    position: relative;
    display: none;
}

.vitec-list a {
	font-size: 14px;
	color: #fff;
	text-decoration: none;
	line-height: 18px;
	padding: 6px 0
}

.vitec-list a:hover {
	color: #fff
}

.vitec-menu.menucoltwoA,
.vitec-menu.menucoltwoB {
	display: none
}

@media(min-width:1241px) {
	.vitec-section .cols-container .cols.col-large-4 {
		width: 33.33%;
		float: left
	}
	.vitec-section .cols-container .cols.col-large-2 {
		width: 16.66%;
		float: left
	}
	.vitec-section .cols-container .cols.col-large-10 {
		width: 83.33%;
		float: left
	}
}

@media(max-width:1240px) {
	.vitec-menu {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2
	}
	.vitec-section .hidden-medium {
		display: none!important
	}
	.vitec-list {
		padding: 40px 0
	}
	.vitec-section h2 {
		padding-bottom: 36px
	}
	.vitec-section .yellow-separator {
		padding-left: 30px
	}
}

@media(max-width:768px) {
	.vitec-menu {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1
	}
	.vitec-section .cols {
		display: block;
		float: none!important;
		width: auto!important
	}
	.vitec-section .yellow-separator {
		padding-left: 0;
		border: 0
	}
	.vitec-list {
		padding: 30px 0 0
	}
	.vitec-section h2 {
		font-size: 30px;
		line-height: 30px;
		padding-bottom: 16px;
		padding-left: 20px
	}
	.vitec-section .container {
		padding: 0 20px
	}
	.vitec-section .collapsible-section .container {
		padding: 0
	}
	ul.vitec-menu ul {
		margin: 0
	}
	.vitec-section .close-vitec-menu {
		right: 20px
	}
	.vitec-list .vitec-menu a,
	.vitec-section h3 {
		padding: 20px 15px;
		line-height: 20px
	}
	.vitec-section h3 {
		font-size: 15px;
		letter-spacing: .1em;
		text-transform: uppercase;
		cursor: pointer;
		border-bottom: 1px solid #333;
		background: url(https://cdn.manfrotto.com/pub/static/frontend/VIS/manfrotto/it_IT/images/vitec-brands-bar/downarrow.png) right 20px center no-repeat;
		padding-right: 35px
	}
	.vitec-list .vitec-menu a {
		background-color: rgba(30, 30, 30, 0);
		transition: all ease-in-out .4s
	}
	.vitec-list .vitec-menu a:hover {
		background-color: rgba(30, 30, 30, 1)
	}
	.vitec-section h3.active {
		background-image: url(https://cdn.manfrotto.com/pub/static/frontend/VIS/manfrotto/it_IT/images/vitec-brands-bar/uparrow.png)
	}
	ul.vitec-menu>li>ul {
		border-bottom: 1px solid #333;
		background-color: #191919;
		display: none
	}
}

.with-chat.vitec .vitec-sites {
	padding-left: 15px;
	float: left
}

.brand-menu__trigger{
  cursor: pointer;
}