@import "bootstrap/scss/functions";
@import "variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

body {
    &.brand-GTZ {
        .vitec-brands .logo-bgi-lg-k {
            display: none;
        }

        .footer-brands .logo-bgi-lg-w {
            display: none;
        }

        .accountpanel {
            background-image: url(./images/home-gitzo.jpg);
        }

        .btn-success,
        .btn-success:hover,
        .btn-success:active {
            color: #fff;
            background-color: #000;
            border-color: #000;
        }

        .btn-success:focus {
            color: #fff;
            background-color: #000;
            border-color: #000;
            box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 50%);
        }

        .form-container>.container.bg-success {
            background-color: #000 !important;
        }

        /**/

        @mixin sprite ($label, $proportion, $bgsize, $position) {
            content: $label;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: $proportion;
            background-image: url(./images/sprite.svg);
            background-position: $position;
            background-size: $bgsize;
            background-repeat: no-repeat;
            text-indent: -999px;
            overflow: hidden;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;

            @media all and (-ms-high-contrast:none) {
                background-size: $bgsize*1.01;
            }

        }


        .accountpanel .brand-logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            display: block;

            &:before {
                @include sprite ('Gitzo', 62.5%, 720%, 6% 8%);
            }
        }

    }

    &.brand-MNF {
        .vitec-brands .logo-man-lg-k {
            display: none;
        }

        .footer-brands .logo-man-lg-w {
            display: none;
        }

        .accountpanel {
            background-image: url(./images/home-manfrotto.jpg);
        }

        .btn-success,
        .btn-success:hover,
        .btn-success:active {
            color: #fff;
            background-color: #e42d24;
            border-color: #e42d24;
        }

        .btn-success:focus {
            color: #fff;
            background-color: #e42d24;
            border-color: #e42d24;
            box-shadow: 0 0 0 0.25rem rgb(228 45 36 / 50%);
        }

        /**/

        @mixin sprite ($label, $proportion, $bgsize, $position) {
            content: $label;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: $proportion;
            background-image: url(./images/sprite.svg);
            background-position: $position;
            background-size: $bgsize;
            background-repeat: no-repeat;
            text-indent: -999px;
            overflow: hidden;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;

            @media all and (-ms-high-contrast:none) {
                background-size: $bgsize*1.01;
            }

        }


        .accountpanel .brand-logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            display: block;

            &:before {
                @include sprite ('Manfrotto', 62.5%, 720%, 6% -0.9%);
            }
        }

    }

    &.brand-LWP {
        .vitec-brands .logo-blw-lg-k {
            display: none;
        }

        .footer-brands .logo-blw-lg-w {
            display: none;
        }

        .accountpanel {
            background-image: url(./images/home-lowepro.jpg);
        }

        .btn-success,
        .btn-success:hover,
        .btn-success:active {
            color: #fff;
            background-color: #f8971d;
            border-color: #f8971d;
        }

        .btn-success:focus {
            color: #fff;
            background-color: #f8971d;
            border-color: #f8971d;
            box-shadow: 0 0 0 0.25rem rgb(248 151 29 / 50%);
        }

        /**/

        @mixin sprite ($label, $proportion, $bgsize, $position) {
            content: $label;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: $proportion;
            background-image: url(./images/sprite.svg);
            background-position: $position;
            background-size: $bgsize;
            background-repeat: no-repeat;
            text-indent: -999px;
            overflow: hidden;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;

            @media all and (-ms-high-contrast:none) {
                background-size: $bgsize*1.01;
            }

        }


        .accountpanel .brand-logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            display: block;

            &:before {
                @include sprite ('Lowepro', 62.5%, 720%, 6% 51%);
            }
        }

    }

    &.brand-JBY {
        .vitec-brands .logo-bjb-lg-k {
            display: none;
        }

        .footer-brands .logo-bjb-lg-w {
            display: none;
        }

        .accountpanel {
            background-image: url(./images/home-joby.jpg);
        }

        .btn-success,
        .btn-success:hover,
        .btn-success:active {
            color: #fff;
            background-color: #2B24C5;
            border-color: #2B24C5;
        }

        .btn-success:focus {
            color: #fff;
            background-color: #2B24C5;
            border-color: #2B24C5;
            box-shadow: 0 0 0 0.25rem rgb(43 36 197 / 50%);
        }

        .form-container>.container {
            border-radius: 15px;
        }

        .form-container>.container.bg-success {
            background-color: #2B24C5 !important;
        }

        /**/

        @mixin sprite ($label, $proportion, $bgsize, $position) {
            content: $label;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: $proportion;
            background-image: url(./images/sprite.svg);
            background-position: $position;
            background-size: $bgsize;
            background-repeat: no-repeat;
            text-indent: -999px;
            overflow: hidden;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;

            @media all and (-ms-high-contrast:none) {
                background-size: $bgsize*1.01;
            }

        }


        .accountpanel .brand-logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            display: block;

            &:before {
                @include sprite ('Joby', 62.5%, 720%, 6% 59.4%);
            }
        }

    }

    &.brand-SPY {
        .vitec-brands .logo-bjb-lg-k {
            display: none;
        }

        .footer-brands .logo-bjb-lg-w {
            display: none;
        }

        .accountpanel {
            background-image: url(./images/home-syrplab.jpg);

            .accountButton {
                background-color: #000;
                color: #fff;
                border-color: #464646;
            }

            input.form-control {
                background: transparent;
                color: white;
                border-color: #404040;
            }
        }

        .btn-success,
        .btn-success:hover,
        .btn-success:active {
            color: #fff;
            background-color: #26bf73;
            border-color: #26bf73;
        }

        .btn-success:focus {
            color: #fff;
            background-color: #26bf73;
            border-color: #26bf73;
            box-shadow: 0 0 0 0.25rem rgb(43 36 197 / 50%);
        }

        .form-container>.container.bg-white {
            background-color: #000 !important;
            color: #fff !important;
        }

        .form-container>.container.bg-success {
            background-color: #26bf73 !important;
        }

        /**/

        @mixin sprite ($label, $proportion, $bgsize, $position) {
            content: $label;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: $proportion;
            background-image: url(./images/sprite.svg);
            background-position: $position;
            background-size: $bgsize;
            background-repeat: no-repeat;
            text-indent: -999px;
            overflow: hidden;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;

            @media all and (-ms-high-contrast:none) {
                background-size: $bgsize*1.01;
            }

        }


        .accountpanel .brand-logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            display: block;

            &:before {
                @include sprite ('Syrplab', 62.5%, 720%, 22.5% 67%);
            }
        }

    }

    &.brand-SAV {
        .vitec-brands .logo-man-lg-k {
            display: none;
        }

        .footer-brands .logo-man-lg-w {
            display: none;
        }

        .accountpanel {
            background-image: url(./images/home-savage.jpg);
        }

        .btn-success,
        .btn-success:hover,
        .btn-success:active {
            color: #fff;
            background-color: #0154a4;
            border-color: #0154a4;
        }

        .btn-success:focus {
            color: #fff;
            background-color: #0154a4;
            border-color: #0154a4;
            box-shadow: 0 0 0 0.25rem rgba(36, 87, 228, 0.5);
        }

        /**/

        @mixin sprite ($label, $proportion, $bgsize, $position) {
            content: $label;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: $proportion;
            background-image: url(./images/sprite.svg);
            background-position: $position;
            background-size: $bgsize;
            background-repeat: no-repeat;
            text-indent: -999px;
            overflow: hidden;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;

            @media all and (-ms-high-contrast:none) {
                background-size: $bgsize*1.01;
            }

        }


        .accountpanel .brand-logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            display: block;

            &:before {
                @include sprite ('Savage', 62.5%, 720%, 6% 92.5%);
            }
        }

    }

    
    &.brand-RYC {
        .vitec-brands .logo-man-lg-k {
            display: none;
        }

        .footer-brands .logo-man-lg-w {
            display: none;
        }

        .accountpanel {
            background-image: url(./images/home-rycote.jpg);
        }

        .btn-success,
        .btn-success:hover,
        .btn-success:active {
            color: #fff;
            background-color: #bf0000;
            border-color: #bf0000;
        }

        .btn-success:focus {
            color: #fff;
            background-color: #bf0000;
            border-color: #bf0000;
            box-shadow: 0 0 0 0.25rem rgb(228 45 36 / 50%);
        }

        /**/

        @mixin sprite ($label, $proportion, $bgsize, $position) {
            content: $label;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: $proportion;
            background-image: url(./images/sprite.svg);
            background-position: $position;
            background-size: $bgsize;
            background-repeat: no-repeat;
            text-indent: -999px;
            overflow: hidden;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;

            @media all and (-ms-high-contrast:none) {
                background-size: $bgsize*1.01;
            }

        }


        .accountpanel .brand-logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            display: block;

            &:before {
                @include sprite ('Rycote', 62.5%, 720%, 6% 74%);
            }
        }

    }
    .videndum-list::after,
    .videndum-section .cols-container:after {
        display: block;
        clear: both;
        content: ""
    }

    .videndum-section * {
        font-family: Arial, Helvetica, sans-serif;
        padding: 0;
        margin: 0;
        font-size: inherit
    }

    .videndum-section .container {
        max-width: 1180px;
        margin: 0 auto;
        padding: 0 30px
    }

    .videndum-section .yellow {
        color: #fecb00
    }

    .videndum-section .collapsible-section {
        background: #000
    }

    .videndum-section {
        background-color: #535353
    }

    .videndum-menu {
        -webkit-column-count: 3;
        /* Chrome, Safari, Opera */
        -moz-column-count: 3;
        /* Firefox */
        column-count: 3;
    }

    .videndum-menu>li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }

    .videndum-section .close-videndum-menu {
        display: block;
        width: 22px;
        height: 22px;
        background: url(./images/close-white.png) no-repeat;
        position: absolute;
        right: 0;
        top: 30px;
        cursor: pointer
    }

    .videndum-section h2 {
        font-size: 37px;
        font-weight: 700;
        color: #fff;
        line-height: 36px
    }

    .videndum-section h3 {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        color: #fff;
        padding: 5px 0
    }

    ul.videndum-menu,
    ul.videndum-menu li,
    ul.videndum-menu li a {
        display: block
    }

    ul.videndum-menu ul {
        margin-bottom: 25px
    }

    .videndum-section h2 span {
        display: block
    }

    .videndum-section .yellow-separator {
        padding-left: 90px;
        border-left: 2px solid #fecb00
    }

    .videndum-section .white-separator {
        padding-left: 90px;
        border-left: 2px solid #ffffff
    }

    .videndum {
        padding: 5px 0;
        display: block
    }

    .videndum::after {
        content: "";
        clear: both;
        display: block;
    }

    .videndum .videndum-logo {
        float: left;
        line-height: 13px;
        font-size: 11px;
        font-weight: 400;
        color: #fff;
        padding-left: 40px;
        position: relative
    }

    .videndum .videndum-logo .logo-image {
        display: block;
        height: 47px;
        width: 150px;
        background: url(./images/videndum-logo.png) center left no-repeat;
        background-size: contain;
    }

    .videndum .videndum-sites {
        padding: 8px 0 7px;
        float: right;
        cursor: pointer;
        line-height: 26px;
        font-size: 13px;
        font-weight: 700;
        color: #fff;
        padding-right: 15px;
        background: url(./images/uparrow-white.png) center right no-repeat
    }

    .videndum .videndum-sites.active {
        background: url(./images/downarrow-white.png) center right no-repeat
    }

    .videndum-list {
        padding: 65px 0;
        position: relative
    }

    .videndum-list a {
        font-size: 14px;
        color: #fff;
        text-decoration: none;
        line-height: 18px;
        padding: 6px 0
    }

    .videndum-list a:hover {
        color: #fff
    }

    .videndum-menu.menucoltwoA,
    .videndum-menu.menucoltwoB {
        display: none
    }

    .text-muted {
        color: #fff;
        padding-left: 90px;
        margin-top: 30px;
        display: block;
        font-size: 80%;
    }

    @media (min-width:1241px) {
        .videndum-section .cols-container .cols.col-large-4 {
            width: 33.33%;
            float: left
        }

        .videndum-section .cols-container .cols.col-large-2 {
            width: 16.66%;
            float: left
        }

        .videndum-section .cols-container .cols.col-large-10 {
            width: 83.33%;
            float: left
        }
    }

    @media (max-width:1240px) {


        .videndum-menu {
            -webkit-column-count: 2;
            /* Chrome, Safari, Opera */
            -moz-column-count: 2;
            /* Firefox */
            column-count: 2;
        }

        .videndum-section .hidden-medium {
            display: none !important
        }

        .videndum-list {
            padding: 40px 0
        }

        .videndum-section h2 {
            padding-bottom: 36px
        }

        .videndum-section .yellow-separator {
            padding-left: 30px
        }
    }

    @media (max-width:768px) {

        .videndum-menu {
            -webkit-column-count: 1;
            /* Chrome, Safari, Opera */
            -moz-column-count: 1;
            /* Firefox */
            column-count: 1;
        }

        .videndum-section .cols {
            display: block;
            float: none !important;
            width: auto !important
        }

        .videndum-section .yellow-separator {
            padding-left: 0;
            border: none
        }

        .videndum-list {
            padding: 30px 0 0
        }

        .videndum-section h2 {
            font-size: 30px;
            line-height: 30px;
            padding-bottom: 16px;
            padding-left: 20px
        }

        .videndum-section .container {
            padding: 0 20px
        }

        .videndum-section .collapsible-section .container {
            padding: 0
        }

        ul.videndum-menu ul {
            margin: 0
        }

        .videndum-section .close-videndum-menu {
            right: 20px
        }

        .videndum-list .videndum-menu a,
        .videndum-section h3 {
            padding: 20px 15px;
            line-height: 20px
        }

        .videndum-section h3 {
            font-size: 15px;
            letter-spacing: .1em;
            text-transform: uppercase;
            cursor: pointer;
            border-bottom: 1px solid #333;
            background: url(./images/downarrow.png) right 20px center no-repeat;
            padding-right: 35px
        }

        .videndum-list .videndum-menu a {
            background-color: rgba(30, 30, 30, 0);
            transition: all ease-in-out .4s
        }

        .videndum-list .videndum-menu a:hover {
            background-color: rgba(30, 30, 30, 1)
        }

        .videndum-section h3.active {
            background-image: url(./images/uparrow.png)
        }

        ul.videndum-menu>li>ul {
            border-bottom: 1px solid #333;
            background-color: #191919;
            display: none
        }
    }
}