@import "bootstrap/scss/functions";
@import "variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";


footer{
    background-color: $black;
    color: $white;
    font-size: $font-size-sm;
    ul.list-group{
        list-style: none;
    }
    a{
        color: $white;
        text-decoration: none;
    }
    .footer-brands{
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
}
.copyright__container{
    background-color: $gray-850;
    .logo{
        margin-top: -23px;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
}
.brand-menu__container{
    background-color: $gray-880;
}