.top-bar {
    position: relative;
    background-color: $gray-200;
    min-height: 40px;


    @include media-breakpoint-down(md) {
        position: static;
        margin-top: -50px;

        .country-selector {
            position: absolute;
            z-index: 99999;
            right: 0px;
            top: 0px;
            border-radius: 0px 0px 0px 10px;
            padding-bottom: 5px;
            background-color: $white;
        }
    }
    .country-selector {
        .btn-link{
            color: $black;
            text-decoration: none;
    
        }
        .dropdown-menu-end{
            right: 0px;
        }
    }

    .position-absolute {
        top: 0;
        right: 0;
        background-color: $gray-200;
    }
}
.dropdown-menu-left {
    right: 0px;
}
.accountpanel {
    display: flex;
    position: relative;
    width: 100%;
    max-height: calc(100vh - 40px);
    min-height: 960px;
    height: 100vh;
    background-image: url(./images/home.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.accountpanel > .form-container {
    position: relative;
    top: 0;
    left: 0;
    min-width: 480px;
    max-width: 1200px;
    margin: auto auto auto auto;
    padding: 0px 15px 10px 15px;
    display: flex;
    column-gap: 30px;
    align-items: stretch;
    @include media-breakpoint-down(lg) {
        padding: 8px 30px 30px 30px;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        min-width: auto;
            width: calc(100% - 30px);
            padding: 0px 10px 10px 10px;
    }
}

.accountButton {
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: white;
    background-repeat: no-repeat;
    transition: background-position ease-in-out 100ms;
    padding: 8px 10px 8px 30px;
    background-position: 8px center;
    background-size: 21px;
    font-size: 1em;

    @include media-breakpoint-down(md) {
        font-size: 0.8em;
    }
}

.account-fb {
    background-image: url(./images/icons/facebook_logo.svg);
}

.account-google {
    background-image: url(./images/icons/google_logo.svg);
}
